import React, { useRef, useState, useEffect } from 'react';
import { SliderWrapper } from './SingleCarSlider.style';
import './SingleCarSlider.css';

const SingleCarSlider = ({ carData: { _id, images } }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  let slider1 = useRef(null);
  let slider2 = useRef(null);

  const imagesLength = images.length;

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, []);

  const openFullscreen = (index) => {
    setCurrentImageIndex(index);
    setIsFullscreen(true);
  };

  const closeFullscreen = () => {
    setIsFullscreen(false);
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <React.Fragment>
      <SliderWrapper className="single-slider" asNavFor={nav2} ref={(slider) => (slider1 = slider)}>
        {images.map((url, i) => (
          <div key={`${i}-${_id}-full-image`} onClick={() => openFullscreen(i)}>
            <div className="main_slider__main_div">
              <img src={url} alt="slider" className="img-fluid" />
            </div>
          </div>
        ))}
      </SliderWrapper>
      <SliderWrapper
        asNavFor={nav1}
        ref={(slider) => (slider2 = slider)}
        slidesToShow={imagesLength > 5 ? 5 : imagesLength}
        swipeToSlide={true}
        focusOnSelect={true}
        arrows={false}
        className="more-slider"
      >
        {images.map((url, i) => (
          <div key={`${i}-${_id}-list-image`}>
            <div className="main_slider__main_div">
              <img src={url} alt="slider" className="img-fluid" />
            </div>
          </div>
        ))}
      </SliderWrapper>

      {isFullscreen && (
        <div className="fullscreen-modal" onClick={closeFullscreen}>
          <button className="prev-button" onClick={(e) => {e.stopPropagation(); handlePrev();}}>
            &#9664;
          </button>
          <img src={images[currentImageIndex]} alt="slider" className="fullscreen-image" />
          <button className="next-button" onClick={(e) => {e.stopPropagation(); handleNext();}}>
            &#9654;
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default SingleCarSlider;
