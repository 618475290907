import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ReactGA from "react-ga";
import Slider from "react-slick";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import "../CustomerReviews/review.css";
// Styled components
import {
  // UIDecorLine,
  SubscribeField,
} from "../Footer/Footer.style";
import "./newsletter.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: "#000",
    color: "#FCC602",
    fontWeight: 900,
    height: 45,
    fontSize: 18,
    alignSelf: "flex-end",
    "&:hover": {
      backgroundColor: "#000",
      color: "#FCC602",
    },
  },
}));

const reviews = [
  {
    name: "Sherylee Je-taime",
    review:
      "The site is affordable and is given our dealerships lots of exposure and lots of customers patronizing us. Definitely will recommend the site if you want to sell cars",
    image: `${process.env.REACT_APP_BASE_PATH}/media/testimonial/1.jpg`,
  },
  {
    name: "Ayodele Solanke",
    review:
      "I recently installed chat2cars tracking device and now I can confidently say that my car is safe",
    image: `${process.env.REACT_APP_BASE_PATH}/media/testimonial/2.jpg`,
  },
  {
    name: "Jabu Afrika Tshabalala",
    review:
      "I've dealt with many Towing companies in the past but the service, courtesy and speed I received from Chat2Cars was THE BEST!!!Outstanding service!!",
    image: `${process.env.REACT_APP_BASE_PATH}/media/testimonial/3.jpg`,
  },
  {
    name: "Moses Eduviere",
    review:
      "Thank you I will highly recommend Chat2Cars to all my friends and family.",
    image: `${process.env.REACT_APP_BASE_PATH}/media/testimonial/4.jpg`,
  },
];

const featuredOn = [
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/abarth.png`,
    brand: [
      {
        maketitle: "abarth",
        _id: "5b800afc587bfa2ef94f08b9",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/alfaRomio.png`,
    brand: [
      {
        maketitle: "Alfa Romio",
        _id: "5b800b10587bfa2ef94f08ba",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Audi.png`,
    brand: [
      {
        maketitle: "Audi",
        _id: "5b800baa587bfa2ef94f08bb",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/bmw.png`,
    brand: [
      {
        maketitle: "BMW",
        _id: "5b800d1f587bfa2ef94f08bd",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/cmc.jpeg`,
    brand: [
      {
        maketitle: "CMC",
        _id: "5b800d6c587bfa2ef94f08c5",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/cadillac.png`,
    brand: [
      {
        maketitle: "Cadillac",
        _id: "5b800d3a587bfa2ef94f08bf",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Chana_Changan.jpeg`,
    brand: [
      {
        maketitle: "Chana Changan",
        _id: "5b800d45587bfa2ef94f08c0",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/chary.png`,
    brand: [
      {
        maketitle: "Chery",
        _id: "5b800d4e587bfa2ef94f08c1",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Chevrolet.png`,
    brand: [
      {
        maketitle: "Chevrolet",
        _id: "5b800d56587bfa2ef94f08c2",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Chrysler.png`,
    brand: [
      {
        maketitle: "Chrysler",
        _id: "5b800d5d587bfa2ef94f08c3",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Citroen.png`,
    brand: [
      {
        maketitle: "Citroen",
        _id: "5b800d65587bfa2ef94f08c4",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Colt.jpeg`,
    brand: [
      {
        maketitle: "Colt",
        _id: "5b800d76587bfa2ef94f08c6",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Daewoo.png`,
    brand: [
      {
        maketitle: "Daewoo",
        _id: "5b800d85587bfa2ef94f08c8",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Daihatsu.png`,
    brand: [
      {
        maketitle: "Daihatsu",
        _id: "5b800d8d587bfa2ef94f08c9",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Datsun.png`,
    brand: [
      {
        maketitle: "Datsun",
        _id: "5b800d95587bfa2ef94f08ca",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Dodge.jpg`,
    brand: [
      {
        maketitle: "Dodge",
        _id: "5b800d9c587bfa2ef94f08cb",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Faw.png`,
    brand: [
      {
        maketitle: "Faw",
        _id: "5b800da9587bfa2ef94f08cc",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Fiat.png`,
    brand: [
      {
        maketitle: "Fiat",
        _id: "5b800dcb587bfa2ef94f08cd",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/ford.png`,
    brand: [
      {
        maketitle: "Ford",
        _id: "5b800dd7587bfa2ef94f08ce",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Foton.png`,
    brand: [
      {
        maketitle: "Foton",
        _id: "5b800e07587bfa2ef94f08d0",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Freightliner.png`,
    brand: [
      {
        maketitle: "Freightliner",
        _id: "5b800e1f587bfa2ef94f08d1",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Fuso.png`,
    brand: [
      {
        maketitle: "Fuso",
        _id: "5b800e25587bfa2ef94f08d2",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/gonow.webp`,
    brand: [
      {
        maketitle: "GONOW",
        _id: "5b800e42587bfa2ef94f08d5",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/gmw.png`,
    brand: [
      {
        maketitle: "GWM",
        _id: "5b800e4b587bfa2ef94f08d6",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/geely.png`,
    brand: [
      {
        maketitle: "Geely",
        _id: "5b800e2d587bfa2ef94f08d3",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/harley-davidson.png`,
    brand: [
      {
        maketitle: "Harley Davidson",
        _id: "5b800e74587bfa2ef94f08d7",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/haval.png`,
    brand: [
      {
        maketitle: "Haval",
        _id: "5c49d23aed8f240a62ba9623",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Hino.png`,
    brand: [
      {
        maketitle: "Hino",
        _id: "5b800e7b587bfa2ef94f08d8",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/honda.png`,
    brand: [
      {
        maketitle: "Honda",
        _id: "5b800e83587bfa2ef94f08d9",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/hyundai.png`,
    brand: [
      {
        maketitle: "Hyundai",
        _id: "5b800e8e587bfa2ef94f08da",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/infinity.png`,
    brand: [
      {
        maketitle: "Infinity",
        _id: "5cdff827c0fe6a783c7c55b4",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/isuzu.png`,
    brand: [
      {
        maketitle: "Isuzu",
        _id: "5b800e9a587bfa2ef94f08db",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/iveco.png`,
    brand: [
      {
        maketitle: "Iveco",
        _id: "5b800ebf587bfa2ef94f08dc",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/jaguar.png`,
    brand: [
      {
        maketitle: "Jaguar",
        _id: "5b800ec7587bfa2ef94f08dd",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/jeep.jpg`,
    brand: [
      {
        maketitle: "Jeep",
        _id: "5b800ece587bfa2ef94f08de",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/kawasaki.png`,
    brand: [
      {
        maketitle: "Kawasaki",
        _id: "5b800ee0587bfa2ef94f08df",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/kia.png`,
    brand: [
      {
        maketitle: "Kia",
        _id: "5b800ee8587bfa2ef94f08e0",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/landrover.png`,
    brand: [
      {
        maketitle: "Landrover",
        _id: "5b800f04587bfa2ef94f08e1",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Lexus.png`,
    brand: [
      {
        maketitle: "Lexus",
        _id: "5b800f0c587bfa2ef94f08e2",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/MAN.png`,
    brand: [
      {
        maketitle: "MAN",
        _id: "5b800f16587bfa2ef94f08e3",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Mahindra.png`,
    brand: [
      {
        maketitle: "Mahindra",
        _id: "5b800f1f587bfa2ef94f08e4",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Mazda.png`,
    brand: [
      {
        maketitle: "Mazda",
        _id: "5b800f28587bfa2ef94f08e5",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Mercedes-Benz.png`,
    brand: [
      {
        maketitle: "Mercedes-Benz",
        _id: "5b800f4b587bfa2ef94f08e6",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Mini.png`,
    brand: [
      {
        maketitle: "Mini",
        _id: "5b800f55587bfa2ef94f08e7",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Mitsubishi.png`,
    brand: [
      {
        maketitle: "Mitsubishi",
        _id: "5b800f5d587bfa2ef94f08e8",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Nissan.png`,
    brand: [
      {
        maketitle: "Nissan",
        _id: "5b800f6c587bfa2ef94f08e9",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Opel.png`,
    brand: [
      {
        maketitle: "Opel",
        _id: "5b800f7a587bfa2ef94f08ea",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Peugeot.jpg`,
    brand: [
      {
        maketitle: "Peugeot",
        _id: "5b800f81587bfa2ef94f08eb",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Porsche.png`,
    brand: [
      {
        maketitle: "Porsche",
        _id: "5b800f8b587bfa2ef94f08ec",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Proton.svg`,
    brand: [
      {
        maketitle: "Proton",
        _id: "5b800f92587bfa2ef94f08ed",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Renault.jpg`,
    brand: [
      {
        maketitle: "Renault",
        _id: "5b800f9f587bfa2ef94f08ee",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/SsangyongKorando.png`,
    brand: [
      {
        maketitle: "SsangyongKorando",
        _id: "5b800fab587bfa2ef94f08ef",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Subaru.png`,
    brand: [
      {
        maketitle: "Subaru",
        _id: "5b800fe8587bfa2ef94f08f0",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Suzuki.png`,
    brand: [
      {
        maketitle: "Suzuki",
        _id: "5b800ff0587bfa2ef94f08f1",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Tata.png`,
    brand: [
      {
        maketitle: "Tata",
        _id: "5b800ff8587bfa2ef94f08f2",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Toyota.jpg`,
    brand: [
      {
        maketitle: "Toyota",
        _id: "5b800fff587bfa2ef94f08f3",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Volkswagen.jpg`,
    brand: [
      {
        maketitle: "Volkswagen",
        _id: "5b80101b587bfa2ef94f08f4",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Volvo.png`,
    brand: [
      {
        maketitle: "Volvo",
        _id: "5b801026587bfa2ef94f08f5",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/Yamaha.png`,
    brand: [
      {
        maketitle: "Yamaha",
        _id: "5b80102a587bfa2ef94f08f6",
      },
    ],
  },
  {
    image: `${process.env.REACT_APP_BASE_PATH}/media/featured/cherry.png`,
    brand: [
      {
        maketitle: "cherry",
        _id: "5f85a6f8eaed41140d89dd7d",
      },
    ],
  },
];

const NewsLetter = (props) => {
  const [email, setEmail] = useState("");
  const [activeReview, setActiveReview] = useState(0);
  const classes = useStyles();

  const reviewsLength = reviews.length - 1;

  const url =
    "https://chat2cars.us19.list-manage.com/subscribe/post?u=d49a3bc5e5f609fabf42187f1&amp;id=ac01804925";
  const handleNextPrevClick = (type) => {
    if (type === "next") {
      if (activeReview === reviewsLength) {
        setActiveReview(0);
      } else {
        setActiveReview(activeReview + 1);
      }
    } else {
      if (activeReview === 0) {
        setActiveReview(reviewsLength);
      } else {
        setActiveReview(activeReview - 1);
      }
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };
  

  const reviewData = reviews[activeReview];

  return (
    <section className="position-relative">
      <Grid container justify="center" className="review-container">
        <Grid
          className="news-letter-testimonials bg-black"
          item
          xs={12}
          sm={12}
          md={6}
        >
          <div className="pl-5 pr-5">
            <h2 className="footer-section__title pb-3 text-white text-uppercase newsletter-title font-weight-900">
              Testimonials
            </h2>
            <div className="d-flex testimonials-in-news-letter">
              <img
                style={{ flex: "0 0 180px", alignSelf: "flex-start" }}
                src={reviewData.image}
                height={180}
                alt=""
              />
              <div className="d-flex flex-column testimonials-in-news-letter-content">
                <h3 className="footer-section__title pb-3 font-size-18 text-white text-uppercase newsletter-title font-weight-900">
                  {reviewData.name}
                </h3>
                <p style={{ flex: "1 0" }} className="text-white mt-0">
                  {reviewData.review}
                </p>
                <div>
                  <IconButton
                    className="rounded-0 bg-white"
                    size="small"
                    onClick={() => handleNextPrevClick("prev")}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  <IconButton
                    className="rounded-0 ml-2 bg-white"
                    size="small"
                    onClick={() => handleNextPrevClick("next")}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            <h2 className="footer-section__title pt-3 pb-3 text-white text-uppercase newsletter-title font-weight-900">
              Featured On
            </h2>
            <div className="max-w-200">

            <Slider {...settings} >
              {featuredOn.map((x, index) => {
                return (
                  <Link
                    key={`featuredOn-${index}`}
                    to={`/listings?brands=${x.brand[0].maketitle}`}
                  >
                    <img
                      key={`featuredOn-${index}`}
                      
                      src={x.image}
                      className="image-w-auto"
                      alt=""
                    />
                  </Link>
                );
              })}
            </Slider>
            </div>
          </div>
        </Grid>
        <Grid
          className="news-letter bg-primary d-flex flex-column justify-content-center"
          item
          xs={12}
          sm={12}
          md={6}
        >
          <div className="pl-5 pr-5">
            <div className="section-head">
              <h2 className="footer-section__title text-uppercase newsletter-title font-weight-900">
                Subscribe to Our Newsletter
              </h2>
              <div className="pt-1" style={{ fontSize: 18 }}>
                Subscribe to get update, offers notification and information.
              </div>
            </div>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <div>
                  <form
                    className="news-letter-form"
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();
                      subscribe({ EMAIL: email });
                      setEmail("");
                      ReactGA.event({
                        category: "conversion",
                        action: "AW-627651729/8NYyCN3zwdkBEJHppKsC",
                      });
                    }}
                  >
                    <SubscribeField
                      className="news-letter-input"
                      id="standard-basic-news-letter"
                      variant="filled"
                      label="Your Email Address"
                      style={{
                        width: "100%",
                        backgroundColor: "#fff",
                        border: "1px solid #000",
                        borderRadius: 8,
                      }}
                      newsletter={true}
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <Button
                      type="submit"
                      className={`${classes.submit} news-letter-submit-button`}
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </form>
                  {status === "sending" && (
                    <div style={{ color: "blue" }}>sending...</div>
                  )}
                  {status === "error" && (
                    <div
                      style={{ color: "red" }}
                      dangerouslySetInnerHTML={{ __html: "Not Subscribed" }}
                    />
                  )}
                  {status === "success" && (
                    <div style={{ color: "green" }}>Subscribed !</div>
                  )}
                </div>
              )}
            />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default NewsLetter;
