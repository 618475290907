import React, { useState, useEffect } from "react";

// For redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchVehicleModelAction,
  removeVehicleModelsFromListAction,
} from "../../redux/Home/Actions";
import { handleLogoutRequestAction } from "../../redux/Login/Actions";

import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";

// Styled Components
import {
  Logo,
  AppBarWrapper,
  SliderWrapper,
  MenuButton,
  MenuListItem,
  ToolbarWrapper,
} from "./Header.style";
import { Link } from "react-router-dom";
import CarFilter from "../CarFilter";

import firebase from "firebase/app";
import "@firebase/firestore";

import "./header.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    // maxWidth: 1200,
    display: "flex",
    alignSelf: "center",
    flexDirection: "column",
    width: "100%",
    zIndex: 1,
    backgroundColor: "#00000059",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flex: 1,
    alignSelf: "center",
  },
  slickImage: {
    width: "100%",
    height: "auto",
    margin: "0 auto",
  },
}));

const Header = (props) => {
  const {
    isAuthenticated,
    user,
    vehicleBrandsList,
    vehicleModelsList,
    carTypes,
    location: { pathname },
  } = props;
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentUserChats, setCurrentUserChat] = useState([]);

  useEffect(() => {
    if (isAuthenticated) {
      firebase
        .firestore()
        .collection("chats")
        .where("users", "array-contains", user.id)
        .where("receiverHasRead", "==", false)
        .onSnapshot((res) => {
          const chats = res.docs.map((doc) => doc.data());
          setCurrentUserChat(chats);
        });
    }
  }, [pathname, isAuthenticated, user]);

  let findHasUnread = undefined;
  if (user) {
    findHasUnread = currentUserChats.find(
      (x) => x.messages[x.messages.length - 1].sender !== user.id
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "ease-in-out",
    fade: true,
  };

  const handleMenuDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const isHomePage = props.location.pathname === "/";

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuCloseAndLogout = () => {
    handleMenuClose();
    props.handleLogoutRequestAction();
  };
  // const handleclose = () => {};

  const isActive = (routeName) => {
    if (pathname === routeName) {
      return true;
    }

    return false;
  };

  const handleFormSubmit = (values) => {
    
    
    const {
      vehicleType,
      vehicleModel,
      vehicleBrand,
      vehicleYear,
      maxPrice,
    } = values;
      
    const newBrands = vehicleBrand?.map((item) => {return item?.maketitle})
    const newModels = vehicleModel?.map((item) => {return item?.modeltitle});  

    const { vehicleModelsList } = props;
    let redirectUrl = `/listings?brands=${newBrands?newBrands:''}&models=${newModels?newModels:''}&year=${vehicleYear}&maxprice=${maxPrice}&types=${vehicleType}&sortby=most_recent`;
    props.history.push(redirectUrl, {
      type: vehicleType,
      selectedModels: vehicleModel,
      modelList: vehicleModelsList, 
      selectedBrands: vehicleBrand,
      year: vehicleYear,
      price: maxPrice !== "" ? [0, maxPrice] : [0, 5000000],
    });
  };

  const handleSelectVehicleBrand = (addedBrand) => {
    props.fetchVehicleModelAction(addedBrand);
  };

  const handleRemoveSelectedBrand = (removeBrand) => {
    props.removeVehicleModelsFromListAction(removeBrand);
  };

  return (
    <React.Fragment>
      <Toolbar className="p-0 header-toolbar">
        <div className="mx-1200 w-100 mx-auto d-flex align-items-center justify-content-end">
          <Logo className={classes.title} />
          {isAuthenticated ? (
            <React.Fragment>
              <div style={{ padding: "6px 8px" }}>
                {findHasUnread ? (
                  <Badge color="secondary" badgeContent=" " variant="dot">
                    <IconButton
                      to={user.is_subscriber ? "/chat" : "/subscriptions"}
                      component={Link}
                      className="p-1"
                      style={{ color: "#000" }}
                    >
                      <NotificationsIcon />
                    </IconButton>
                  </Badge>
                ) : (
                  <IconButton
                    to={user.is_subscriber ? "/chat" : "/subscriptions"}
                    component={Link}
                    className="p-1"
                    style={{ color: "#000" }}
                  >
                    <NotificationsIcon />
                  </IconButton>
                )}
              </div>
              <Button
                style={{ padding: "6px 8px" }}
                color="inherit"
                className="avatar-profile-button"
                onClick={handleProfileMenuOpen}
              >
                <Avatar
                  alt={user.fullname}
                  style={{ margin: "0 8px 0 0" }}
                  src={user.avatarurl}
                />
                <p className="m-0 header-fullname">{user.fullname}</p>
              </Button>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                id="primary-search-account-menu"
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={handleMenuClose}
              >
                <MenuItem component={Link} to="/profile" color="inherit">
                  Profile
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={user.is_subscriber ? "/chat" : "/subscriptions"}
                  color="inherit"
                >
                  Chat
                </MenuItem>
                <MenuItem component={Link} to="/post/ad" color="inherit">
                  Post Ad
                </MenuItem>
                <MenuItem onClick={handleMenuCloseAndLogout}>Logout</MenuItem>
              </Menu>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <MenuButton
                className={
                  isActive("/login") ? "active font-size-14" : "font-size-14"
                }
                component={Link}
                to="/login"
                color="inherit"
              >
                Login
              </MenuButton>
              <Divider orientation="vertical" />
              <MenuButton
                className={
                  isActive("/register") ? "active font-size-14" : "font-size-14"
                }
                component={Link}
                to="/register"
                color="inherit"
              >
                Register
              </MenuButton>
            </React.Fragment>
          )}
          <MenuButton
            className="mr-3 p-3 my-2 text-center book-button font-size-14 "
            sx={{
              display:'none'
            }}
            size="small"
            component={Link}
            to="/book-test-drive"
            color="inherit"
          >
            Book A Test Drive
          </MenuButton>
        </div>
      </Toolbar>
      <AppBarWrapper
        fontcolor="#fff"
        backgroundcolor="transparent"
        position="static"
      >
        <div className={`${classes.root}`}>
          <ToolbarWrapper className=" mx-1200 w-100 mx-auto p-0 header-toolbar-menu">
            <Logo className={classes.title}>
              <Link to="/">
                <img
                  src={`${process.env.REACT_APP_BASE_PATH}/logo.png`}
                  alt="logo"
                />
              </Link>
            </Logo>
            <div className="navigation-links">
              <MenuButton
                className={isActive("/") ? "active" : ""}
                component={Link}
                to="/"
                color="inherit"
              >
                Home
              </MenuButton>
              {/* <MenuButton className={isActive('/listings') ? 'active mr-3' : 'mr-3'} component={Link} to="/listings" color="inherit">Listings</MenuButton> */}
              <MenuButton
                className={isActive("/listings") ? "active mr-3" : "mr-3"}
                component={Link}
                to="/listings"
                color="inherit"
              >
                Buy A Car
              </MenuButton>
              <MenuButton
                className={isActive("/post/ad") ? "active mr-3" : "mr-3"}
                component={Link}
                to="/post/ad"
                color="inherit"
              >
                Sell A Car
              </MenuButton>
              {/* <MenuButton className={isActive('/blog') ? 'active' : ''} component={Link} to="/blog" color="inherit">Blog</MenuButton> */}
              <MenuButton
                className={isActive("/blog") ? "active" : ""}
                component={Link}
                to="/blog"
                color="inherit"
              >
                News & Reviews
              </MenuButton>
              <MenuButton
                className={isActive("/insurance") ? "active" : ""}
                component={Link}
                to="/insurance"
                color="inherit"
              >
                Insurance
              </MenuButton>
              {/* <MenuButton className={isActive('/dealers') ? 'active' : ''} component={Link} to="/dealers" color="inherit">Dealers</MenuButton>
              <MenuButton className={isActive('/subscriptions') ? 'active' : ''} component={Link} to="/subscriptions" color="inherit">Subscriptions</MenuButton> */}
            </div>
            {drawerOpen === true ? (
              <CloseIcon
                className="close_menu_icon"
                onClick={handleMenuDrawerToggle}
              />
            ) : (
              <IconButton
                className="navigation-links-menu-icon mr-3 p-2"
                aria-label="main menu"
                onClick={handleMenuDrawerToggle}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
          </ToolbarWrapper>
        </div>
        {isHomePage && (
          <div className="bgColor">
            <div className="mx-1200 mx-auto d-flex align-items-center">
              <SliderWrapper {...settings}>
                <div style={{ outline: "none" }}>
                  <div className="main_slider__main_div mx-auto justify-content-between">
                    <img
                      style={{ flex: 1, width: "50%" }}
                      src={`${process.env.REACT_APP_BASE_PATH}/media/header/buy-or-sell.png`}
                      alt="slider"
                    />
                  </div>
                </div>
                <div style={{ outline: "none" }}>
                  <div className="main_slider__main_div mx-auto justify-content-between">
                    <img
                      style={{ flex: 1, width: "50%" }}
                      src={`${process.env.REACT_APP_BASE_PATH}/media/header/roadside-assistance.png`}
                      alt="slider"
                    />
                  </div>
                </div>
                <div style={{ outline: "none" }}>
                  <div className="main_slider__main_div mx-auto justify-content-between">
                    <img
                      style={{ flex: 1, width: "50%" }}
                      src={`${process.env.REACT_APP_BASE_PATH}/media/header/track-your-car.png`}
                      alt="slider"
                    />
                  </div>
                </div>
              </SliderWrapper>
              <CarFilter
                classNames={`max-1200 car-filter-header`}
                handleFormSubmit={handleFormSubmit}
                handleSelectVehicleBrand={handleSelectVehicleBrand}
                handleRemoveSelectedBrand={handleRemoveSelectedBrand}
                vehicleBrandsList={vehicleBrandsList}
                vehicleModelsList={vehicleModelsList}
                carTypes={carTypes}
              />
            </div>
          </div>
        )}

        <SwipeableDrawer
          anchor="left"
          open={drawerOpen}
          onClose={handleMenuDrawerToggle}
          onOpen={handleMenuDrawerToggle}
          className="swipe-drawer-main-menu"
        >
          <List>
            <MenuListItem button component={Link} to="/">
              <ListItemText
                className={isActive("/") ? "active" : "not-active"}
                primary={"Home"}
              />
            </MenuListItem>
            <MenuListItem button component={Link} to="/listings">
              <ListItemText
                className={isActive("/listings") ? "active" : "not-active"}
                primary={"Buy a Car"}
              />
            </MenuListItem>
            <MenuListItem button component={Link} to="/post/ad">
              <ListItemText
                className={isActive("/post/ad") ? "active" : "not-active"}
                primary={"Sell a Car"}
              />
            </MenuListItem>
            <MenuListItem button component={Link} to="/blog">
              <ListItemText
                className={isActive("/blog") ? "active" : "not-active"}
                primary={"News & Reviews"}
              />
            </MenuListItem>
            <MenuListItem button component={Link} to="/insurance">
              <ListItemText
                className={isActive("/insurance") ? "active" : "not-active"}
                primary={"Insurance"}
              />
            </MenuListItem>
            {/* <MenuListItem button component={Link} to="/dealers">
              <ListItemText className={isActive('/dealers') ? 'active' : 'not-active'} primary={'Dealers'} />
            </MenuListItem>
            <MenuListItem button component={Link} to="/subscriptions">
              <ListItemText className={isActive('/subscriptions') ? 'active' : 'not-active'} primary={'Subscriptions'} />
            </MenuListItem> */}
          </List>
        </SwipeableDrawer>
      </AppBarWrapper>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Login.isAuthenticated,
    user: state.Login.user,
    vehicleBrandsList: state.Home.vehicleBrandsList,
    vehicleModelsList: state.Home.vehicleModelsList,
    carTypes: state.Home.carTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        handleLogoutRequestAction,
        fetchVehicleModelAction,
        removeVehicleModelsFromListAction,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);