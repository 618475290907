import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { UIDecorLine } from '../Footer/Footer.style';
import { SliderWrapper } from './LatestListing.style';

import SingleCar from './SingleCar';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
}));

const LatestListing = ({ classNames, carListing }) => {
  const classes = useStyles();

  const options = {
    // centerMode: true,
    centerPadding: '80px',
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    arrows: true,
    // cssEase: 'ease-in-out',
    // fade: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: true,
          centerPadding: '40px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <section className={`${classNames} latest-listings mt-3`}>
      <div className={`latest-offers ${classes.root}`}>
        <div className="section-head">
          <h2 className="text-uppercase section-title">
            Latest Listings
          </h2>
          <UIDecorLine />
        </div>
        <SliderWrapper className="mt-3 mb-5" {...options} noMaxHeight>
          {carListing.map(car => {
            return (
              <div key={car._id} style={{ outline: 'none' }}>
                <SingleCar
                  image={car.featured_image}
                  title={car.title}
                  price={car.price}
                  make={car.make}
                  model={car.model}
                  condition={car.condition}
                  year={car.year}
                  transmission={car.transmission}
                  fuel={car.fueltype}
                  kmsDriven={car.mileage}
                  link={`listing/${car.slug}`}
                />
              </div>
            );
          })}
          {/* <div style={{ outline: 'none' }}>
            <SingleCar
              image={`${process.env.REACT_APP_BASE_PATH}/media/offers/1.png`}
              title="Lexus A35 Quad"
              price="65,400"
              condition="New"
              year="2019"
              transmission="Manual"
              fuel="Petrol"
              kmsDriven="54k mi"
              link={`listing/2011-ford-ranger-extra-cab-2.5-engine-capacity-diesel-1578662994396`}
            />
          </div>
          <div style={{ outline: 'none' }}>
            <SingleCar
              image={`${process.env.REACT_APP_BASE_PATH}/media/offers/2.png`}
              title="Lexus A35 Quad"
              price="65,400"
              condition="New"
              year="2019"
              transmission="Manual"
              fuel="Petrol"
              kmsDriven="54k mi"
              link={`listing/2011-ford-ranger-extra-cab-2.5-engine-capacity-diesel-1578662994396`}
            />
          </div>
          <div style={{ outline: 'none' }}>
            <SingleCar
              image={`${process.env.REACT_APP_BASE_PATH}/media/offers/3.png`}
              title="Lexus A35 Quad"
              price="65,400"
              condition="New"
              year="2019"
              transmission="Manual"
              fuel="Petrol"
              kmsDriven="54k mi"
              link={`listing/2011-ford-ranger-extra-cab-2.5-engine-capacity-diesel-1578662994396`}
            />
          </div> */}
        </SliderWrapper>
      </div>
    </section>
  )
}

export default LatestListing
