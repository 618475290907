import React, { Component, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import TelegramIcon from "@material-ui/icons/Telegram";
import IconButton from "@material-ui/core/IconButton";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import ReactGA from "react-ga";


import { Redirect, Link, useParams } from "react-router-dom";
import "./thankyou.css";
import {
  // UIDecorLine,
  SubscribeField,
} from "../../components/Footer/Footer.style";

// const useStyles = makeStyles(theme => ({
//     thankYou: {
//       maxWidth: 1200,
//     },}))

export default function ThankYou(props) {
  // console.log(props);
  const [email, setEmail] = useState("");
  const url =
    "https://chat2cars.us19.list-manage.com/subscribe/post?u=d49a3bc5e5f609fabf42187f1&amp;id=ac01804925";
  // const pr=useParams();
  // console.log(props);
  // var label = 'Kindly enter your email';
  return (
    <div className="thankYou" style={{ margin: "25px 0px 0px 0px" }}>
      <div className="thanku_main_inner">
        <div className="thanku_inner_inner">
          <div className="thanku_title_cls">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              className="font-weight-bold"
            >
              THANK YOU
            </Typography>
            <Typography
              component="h5"
              variant="h6"
              align="center"
              color="textPrimary"
              className="font-weight-bold"
              style={{ fontSize: 16 }}
            >
              Thank you for your inquiry!
            </Typography>
            <div className="thanku_text_cls">
              <Typography
                component="p"
                variant="h6"
                align="center"
                color="textPrimary"
                className="font-weight-normal"
                style={{ fontSize: 16, lineHeight: "24px" }}
              >
                {}We will get back to you soon as possible.
                <br /> Here are some other cars available similar to the one you
                <br /> just selected.
              </Typography>
            </div>
          </div>
          <div
            className="thanku_click_more"
            align="center"
            style={{ margin: "16px 0px" }}
          >
            <Link to="/listings">
              <Button
                type="submit"
                variant="contained"
                startIcon={<ArrowRightAltIcon />}
                style={{
                  backgroundColor: "#F5C82A",
                  textTransform: "none",
                  fontWeight: "bold",
                  borderRadius: "0px",
                  boxShadow: "none",
                  padding: "12px 20px",
                }}
                // onClick={()=>{props.history.go(-1)}}
              >
                <span>Click here to view more</span>
              </Button>
            </Link>
          </div>
          <div className="thanku_main_connect">
            <div className="thanku_connect thanku_inline_cls">
              <p>Connect with us on &nbsp;</p>
            </div>
            <div className="thanku_social_icon thanku_inline_cls">
              <FacebookIcon
                onClick={() =>
                  window.open("https://www.facebook.com/Chat2cars")
                }
                style={{ fontSize: "22px" }}
              />
              {/* <FontAwesomeIcon icon={faFacebookF} /> */}
              {/* <i class="fab fa-facebook-f"></i> */}
              &nbsp;
              <TwitterIcon
                onClick={() => window.open("https://twitter.com/Chat2cars")}
                style={{ fontSize: "22px" }}
              />
              &nbsp;
              <InstagramIcon
                onClick={() =>
                  window.open("https://www.instagram.com/Chat2cars")
                }
                style={{ fontSize: "22px" }}
              />
            </div>
            <div className="thanku_inline_cls thanku_subscribe">
              <p>&nbsp; or subscribe &nbsp;</p>
            </div>
            <div className="thanku_inline_cls thanku_news_clss">
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <div>
                    <form
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                        subscribe({ EMAIL: email });
                        setEmail("");
                        ReactGA.event({
                          category: "conversion",
                          action: "AW-627651729/8NYyCN3zwdkBEJHppKsC",
                        });
                      }}
                    >
                      <SubscribeField
                        className="news-letter-input thanku_input"
                        id="standard-basic-news-letter"
                        variant="filled"
                        label="Kindly enter your email"
                        style={{
                          width: "auto",
                          backgroundColor: "#fff",
                          borderRadius: 8,
                        }}
                        newsletter={true}
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <IconButton type="submit" className="icon_thankyou">
                        <TelegramIcon />
                      </IconButton>
                    </form>
                    {status === "sending" && (
                      <div style={{ color: "blue" }}>sending...</div>
                    )}
                    {status === "error" && (
                      <div
                        style={{ color: "red" }}
                        dangerouslySetInnerHTML={{ __html: "Not Subscribed" }}
                      />
                    )}
                    {status === "success" && (
                      <div style={{ color: "green" }}>Subscribed !</div>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="thanku_bg_img"></div>
        </div>
      </div>
    </div>
  );
}
