import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import SpeedIcon from '@material-ui/icons/Speed';
import BuildIcon from '@material-ui/icons/Build';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';

import { Link } from 'react-router-dom';

import './CarListing.css';
import formatNumber from '../../helpers/formatNumber';

const useStyles = makeStyles(theme => ({
  unfavourite: {
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
}));

const CarListing = ({ carData, isFavouriteList, onClickUnfavourite }) => {
  const classes = useStyles();

  const handleUnfavourite = () => {
    onClickUnfavourite(carData._id);
    let favorites = localStorage.getItem("favorite");
    favorites = favorites ? JSON.parse(favorites) : [];
    const updatedFavorites = favorites.filter(id => id !== carData._id);
    localStorage.setItem("favorite", JSON.stringify(updatedFavorites));
  };

  return (
    <div className="well ad-listing shadow">
      <div className="position-relative img-box__main">
        <div className="img-box">
          <Link
            className="car-title"
            title={carData.title}
            to={`/listing/${carData.slug}`}
          >
            <img src={carData.featured_image} className="img-responsive" alt={carData.slug} />
          </Link>
          <div className="total-images">
            <strong>{carData.images.length}</strong> photos
          </div>
        </div>
        <span className="ad-status"> Featured </span>
        <div className="user-preview">
          <Link to={`/dealer/${carData.creator}`}>
            <Avatar />
          </Link>
        </div>
      </div>
      <div className="content-area">
        <div className="car__main-details">
          <Typography variant="body2" color="textSecondary" component="div">
            <h3 className="car-title mt-0">
              <Link to={`/listing/${carData.slug}`} title={carData.title}>{carData.title}</Link>
            </h3>
          </Typography>
          <div className="car-listing__item">
            <LocationOnIcon /> <div className="car-listing__item_content">{carData.address}</div>
          </div>
          <Typography variant="body1" color="textSecondary" component="div" className="car-location car-features">
            <div className="car-location car-feature">
              <LocalGasStationIcon fontSize="small" className="car-feature-icon" /> <span className="pl-2">{carData.fueltype}</span>
            </div>
            <div className="car-location car-feature">
              <SpeedIcon fontSize="small" className="car-feature-icon" /> <span className="pl-2">{carData.mileage} km</span>
            </div>
            <div className="car-location car-feature">
              <SettingsIcon fontSize="small" className="car-feature-icon" /> <span className="pl-2">{carData.engine} cc</span>
            </div>
            <div className="car-location car-feature">
              <BuildIcon fontSize="small" className="car-feature-icon" /> <span className="pl-2">{carData.transmission}</span>
            </div>
            <div className="car-location car-feature">
              <CalendarTodayIcon fontSize="small" className="car-feature-icon" /> <span className="pl-2">Year {carData.year}</span>
            </div>
          </Typography>
        </div>
        <div className="car__pricing-other-details">
          <div className="short-info">
            <div className="ad-stats hidden-xs">
              <span>Condition : </span>{carData.condition}
            </div>
            <div className="ad-stats hidden-xs">
              <span>Type : </span>{carData.drive}
            </div>
            <div className="ad-stats hidden-xs">
              <span>Make : </span>{carData.make}
            </div>
          </div>
          <div className="price">
            <span>{formatNumber(carData.price, 2)}</span>
          </div>
          {isFavouriteList &&
            <Button
              fullWidth
              variant="contained"
              color="default"
              className={classes.unfavourite}
              onClick={handleUnfavourite}
            >
              UnFavorite
            </Button>
          }
        </div>
      </div>
    </div>
  );
};

export default CarListing;
