import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CarCard from "./../CarCard";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CarListingSkeleton from "./CarListingSkeleton";
import MuPagination from "./../MuPagination/index";
import { getCurrentUserProfileAction } from "../../redux/Profile/Actions";
import { useLocation, useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CarSearchListing = ({
  loading,
  searchResults,
  sortBy,
  currentPage,
  limit,
  totalResults,
  handleChangeOtherFieldsData,
  handleLikeUnLikeCar,
  isAuthenticated
}) => {
  const classes = useStyles();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const brandsParams = searchParams.get("brands");
  const modelParams = searchParams.get("models");
  const yearParams = searchParams.get("year");
  const maxPriceParams = searchParams.get("maxprice");
  const minPriceParams = searchParams.get("minprice");
  const bodyTypesParams = searchParams.get("types");
  const pageParams = searchParams.get("page");
  const fuelParams = searchParams.get("fueltypes");
  const transmissionParams = searchParams.get("transmissiontypes");
  const sortyByParams = searchParams.get("sortby");
  const history = useHistory();

  const clickHandler = (pageno) => {
    history.push(
      `/listings?brands=${brandsParams ? brandsParams : ""}&models=${
        modelParams ? modelParams : ""
      }&year=${yearParams ? yearParams : ""}&maxprice=${
        maxPriceParams ? maxPriceParams : 5000000
      }&minprice=${minPriceParams ? minPriceParams : 0}&types=${
        bodyTypesParams ? bodyTypesParams : ""
      }&fueltypes=${fuelParams ? fuelParams : ""}&transmissiontypes=${
        transmissionParams ? transmissionParams : ""
      }&page=${pageno}&sortby=${sortyByParams ? sortyByParams : "most_recent"}`
    );
  };

  const sortByURL = (e) => {
    history.push(
      `/listings?brands=${brandsParams ? brandsParams : ""}&models=${
        modelParams ? modelParams : ""
      }&year=${yearParams ? yearParams : ""}&maxprice=${
        maxPriceParams ? maxPriceParams : 5000000
      }&minprice=${minPriceParams ? minPriceParams : 0}&types=${
        bodyTypesParams ? bodyTypesParams : ""
      }&fueltypes=${fuelParams ? fuelParams : ""}&transmissiontypes=${
        transmissionParams ? transmissionParams : ""
      }&page=${pageParams ? pageParams : 1}&sortby=${e.target.value}`
    );
  };

  const [store, setStore] = useState(1);

  useEffect(() => {
    setStore(parseInt(pageParams));
  }, [clickHandler]);

  return loading ? (
    <CarListingSkeleton />
  ) : (
    <Container className="car-results__listing p-2">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={sortyByParams || "most_recent"}
            onChange={
              (event) => sortByURL(event)
              // handleChangeOtherFieldsData("sortBy", event.target.value)
            }
          >
            <MenuItem value={"price_low_high"}>Price (Low to High)</MenuItem>
            <MenuItem value={"price_high_low"}>Price (High to Low)</MenuItem>
            <MenuItem value={"year_low_high"}>Year (Low to High)</MenuItem>
            <MenuItem value={"year_high_low"}>Year (High to Low)</MenuItem>
            <MenuItem value={"mileage_low_high"}>
              Mileage (Low to High)
            </MenuItem>
            <MenuItem value={"mileage_high_low"}>
              Mileage (High to Low)
            </MenuItem>
            <MenuItem value={"most_recent"}>Most Recent</MenuItem>
          </Select>
          <FormHelperText>Sort by</FormHelperText>
        </FormControl>
        {totalResults !== 0 && (
          <MuPagination
            className="m-2"
            limit={limit}
            offset={(store - 1) * limit}
            total={totalResults}
            onClickPageNo={(e, offset, pageNumber) => {
              clickHandler(pageNumber);
              // handleChangeOtherFieldsData("pageNumber", pageNumber);
            }}
          />
        )}
      </div>
      <div>
        {searchResults?.length === 0 ? (
          <Typography
            style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100vh", width:"100%"}}
            className="text-dark font-weight-bold text-capitalize"
            color="textPrimary"
            component="h1"
            variant="h5"
          >
            No Data found...
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {searchResults.map((car) => {
              return (
                <CarCard
                  key={car._id}
                  carData={car}
                  featured={car.carfeatures}
                  handleLikeUnLikeCar={handleLikeUnLikeCar}
                  currentPage={currentPage}
                  isAuthenticated={isAuthenticated}
                />
              );
            })}
          </Grid>
        )}
      </div>
      <div className="pt-3" style={{ width: "100%", textAlign: "right" }}>
      {totalResults !== 0 && (
          <MuPagination
            className="m-2"
            limit={limit}
            offset={(store - 1) * limit}
            total={totalResults}
            onClickPageNo={(e, offset, pageNumber) => {
              clickHandler(pageNumber);
            }}
          />
        )}
        </div>
    </Container>
  );
};

export default CarSearchListing;
